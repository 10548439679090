/* eslint-disable react/no-unescaped-entities */
import { motion, useViewportScroll } from 'framer-motion';
import React from 'react';

const Letter = ({ myKey }: {myKey: number}) => {
  const { scrollYProgress } = useViewportScroll();
  return (
    <motion.div key={myKey} style={{ opacity: scrollYProgress }} className="Letter center-flex" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 4 }}>
      <motion.div className="title p">
        {console.log('@@@@@ key', myKey)}
        Good morning Love 💛
      </motion.div>
      <motion.div className="text p">
        I really miss you right now and I'll try to do the sweetest thing for you.
      </motion.div>
      <motion.div className="text">
        Whatever happens between us know this:
      </motion.div>
      <motion.div className="text">
        You are a very special girl.
      </motion.div>
      <motion.div className="text">
        You smile is magnificent.
      </motion.div>
      <motion.div className="text">
        You heart is gentle.
      </motion.div>
      <motion.div className="text">
        You are strong!
      </motion.div>
      <motion.div className="text">
        I want to spend my life and grow with you. Grow together, grow old.
      </motion.div>
      <motion.div className="text">
        If that is meant for us I do not know.
      </motion.div>
      <motion.div className="text">
        With or without me - you can and you will be happy.
      </motion.div>
      <motion.div className="text">
        I sincerely wish that for you.
      </motion.div>
      <motion.div className="text">
        Be happy and smile everyday - your smile makes the world a better place!
      </motion.div>
    </motion.div>
  );
};

export default Letter;
