/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
// import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { ReactComponent as LeftSVG } from '../../img/left.svg';
import { ReactComponent as RightSVG } from '../../img/right.svg';
import Letter from './Letter';

const HomePage = ({ darkMode }: {darkMode: boolean}) => {
  const [page, setPage] = useState(1);

  const setNextPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const setPrevPage = useCallback(() => {
    if (page === 1) return;
    setPage(page - 1);
  }, [page]);

  return (
    <div className="HomePage">
      <div
        onMouseUp={setNextPage}
        className="right-container center hand"
      >
        <RightSVG fill="white" />
      </div>
      {page !== 1 && (
      <div
        onMouseUp={setPrevPage}
        className="left-container center hand"
      >
        <LeftSVG fill="white" />
      </div>
      )}
      <AnimatePresence>
        <Letter myKey={page} />
      </AnimatePresence>
      {console.log('@@@@@ page', page)}
      {console.log('@@@@@ borko', darkMode, page, setPage)}
    </div>
  );
};

export default HomePage;
