/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import { Modal } from '@mui/material';
import { Formik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { post, searchBooks } from '../../api/api';
import { ReactComponent as AddBookSVG } from '../../img/add-book.svg';
import user from '../../Stores/UserStore';
import Overlay from '../Shared/Overlay';

interface NewBookInterface{
    // eslint-disable-next-line no-unused-vars
    addBook: (book:any) => void,
  }

const NewBook = ({ addBook }:NewBookInterface) => {
  const [open, setOpen] = useState(false);
  const close = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Overlay isSelected={open} redirect={false} handleClose={close} noAnimatePresence />
      <div className="item" onClick={() => setOpen(true)}>
        <AddBookSVG />
        <div>Add a book...</div>
      </div>
      {
         document.getElementById('App') && ReactDOM.createPortal(<AnimatePresence>
           {open && (
           <motion.div
             onMouseUp={close}
             className="new-book-modal"
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
           >
             <motion.div
               initial={{
                 top: '70px',
                 left: '60px',
                 scale: 0.2,
                 translateX: '-50%',
                 translateY: '-50%',
                 opacity: 0,
               }}
               animate={{
                 top: '50%',
                 left: '50%',
                 translateX: '-50%',
                 translateY: '-50%',
                 scale: 1,
                 opacity: 1,
               }}
               exit={{
                 top: '70px',
                 left: '60px',
                 scale: 0.2,
                 opacity: 0,
               }}
               className="modal-body"
               transition={{ duration: 0.55 }}
               onMouseUp={(event) => event.stopPropagation()}
             >
               <motion.div className="inner">
                 <Formik
                   initialValues={{ bookTitle: '', bookUrl: '' }}
                   onSubmit={(values, { setSubmitting }) => {
                     searchBooks(values.bookTitle).then((data) => {
                       if (data) {
                         const { items } = data.data;
                         const item = items[0];
                         if (!item) return;
                         const { volumeInfo } = item;
                         const {
                           title, subtitle, authors, description, imageLinks,
                         } = volumeInfo;
                         post('api/books', {
                           title,
                           subtitle,
                           author: authors[0],
                           url: values.bookUrl,
                           user: user.id,
                           description,
                           coverUrl: imageLinks.thumbnail,
                         }).then((book) => {
                           addBook(book);
                         });
                       }
                     });
                     setTimeout(() => {
                       // alert(JSON.stringify(values, null, 2));
                       setSubmitting(false);
                     }, 400);
                   }}
                 >
                   {({
                     values,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     isSubmitting,
                   }) => (
                     <form onSubmit={handleSubmit}>
                       <div className="input p">
                         <input
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                           autoFocus
                           className="p"
                           type="text"
                           name="bookTitle"
                           placeholder="Book Title"
                           onChange={handleChange}
                          // onChange={(event) => {
                          //   handleChange(event);
                          // }}
                           onBlur={handleBlur}
                           value={values.bookTitle}
                         />
                       </div>
                       <div className="input p">
                         <input
                           className="p"
                           type="text"
                           name="bookUrl"
                           placeholder="Book Url"
                           onChange={handleChange}
                           onBlur={handleBlur}
                           value={values.bookUrl}
                         />
                       </div>
                       <button
                         type="submit"
                         className="submit item hand"
                         disabled={isSubmitting}
                       >
                         Submit
                       </button>
                     </form>
                   )}
                 </Formik>
               </motion.div>

             </motion.div>
           </motion.div>
           )}
           {' '}

                                                                 </AnimatePresence>, document.getElementById('App'))
      }

    </>
  );
};

export default NewBook;
